import styled from 'styled-components'
import Container from 'components/Container'
import media from 'styles/media'
import { color } from 'const/colors'

export const BodyText = styled.div`
  color: ${color.black.o100};
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 120px;

  ${media.tablet`
    font-size: 18px;
    line-height: 28px;
    margin: 0;
  `}

  figure img {
    max-width: 140%;
    margin-left: 50%;
    transform: translateX(-50%);

    ${media.phone`
      width: 110%;
      position: relative;
    `}
  }

  figure {
    margin: 100px 0 90px;

    ${media.phone`
      margin: 50px 0 40px;
    `}
  }

  ${media.phone`
    padding-bottom: 50px;
  `}
`

export const ServiceContainer = styled(Container)`
  margin: 0 auto;

  ${media.tablet`
    max-width: 480px;
  `}

  ${media.phone`
    max-width: 312px;
  `}
    ${media.desktop`
    max-width: 580px;
    margin: 0 24px;
   `}
   ${media.desktopLarge`
    max-width: 680px;
    margin: 0 24px;
   `}
`
